/* 会社概要ページ */
<template>
  <div class="company">
  </div>
  <div class="base">
    <div class="new">
      <h1>会社概要</h1>
      <div class="list">
        <div class="title">
          <h2>会社コンセプト</h2>
        </div>
        <div class="titleTwo">
          <p>地球にも優しく<br>好きな服を好きな時に</p>
        </div>
        <ul>
          <li>
            <p>
              好きな服を好きな時に。<br>
              をコンセプトに普段高くて着たくても着れない服を<br>
              誰もがお手軽に着ていただけるようにするため。<br>
              <br>
              また、今現在多くのブランドがありすぎる為、何を買っていいか分からない<br>
              今すぐ着たいが高くて買えないなどそんなお悩みをすぐに解決するサービス。<br>
              これが次世代の服の着方である。
            </p>
          </li>
          <li>
            <div class="last">
              <div class="one">
                <img src="@/assets/company/companyOne.png" alt="" />
                <p>衣服の寿命を延ばし<br>無駄を無くす</p>
              </div>
              <div class="two">
                <img src="@/assets/company/companyTwo.png" alt="" />
                <p>サスティナブルな<br>社会の実現<br>未来のファッション</p>
              </div>
              <div class="three">
                <img src="@/assets/company/companyThree.png" alt="" />
                <p>衣類の循環<br>持続可能な<br>社会の構築</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="name">
        <ul>
          <li><span class="bold">会社名</span> ...........NICOTES.</li>
          <li><span class="bold">代表者</span> .............瀬川 開生</li>
          <li><span class="bold">電話番号</span> ...000-0000-0000</li>
          <li><span class="bold">FAX</span> ......000-0000-0000</li>
        </ul>
        <router-link to="/subscribe">テスト</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Company'
}
</script>

<style scoped>
.company {
  background-image: url("~@/assets/company/company.jpg");
  background-size: cover;
  background-position: center;
  position:fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

.base {
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 70px;
  padding-bottom: 70px;
}

.new {
  margin: 0 auto;
}

.new h1 {
  color: rgb(245, 245, 245);
  letter-spacing: 5px;
  border-top: 1px solid rgb(245, 245, 245);
  border-bottom: 1px solid rgb(245, 245, 245);
  width: 250px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.list {
  position: relative;
  background-color: rgb(245, 245, 245);
  margin: 70px auto;
}

.list ul {
  padding-left: 0;
  list-style: none;
  text-align: left;
  margin: 0;
  padding-bottom: 40px;
}

.title h2 {
  position: absolute;
  background-color: rgb(25, 25, 25);
  color: rgb(245, 245, 245);
  padding-right: 10px;
  padding-left: 10px;
  letter-spacing: 5px;
}

.titleTwo {
  text-align: left;
  font-weight: 600;
  letter-spacing: 8px;
  font-size: 26px;
}

.last img {
  display: block;
}

.last p {
  line-height: 20px !important;
  font-size: 12px;
}

.name ul {
  padding-left: 0;
  list-style: none;
  line-height: 30px;
  color: white;
}

.bold {
  font-weight: bold;
}

@media screen and (min-width: 751px) {
  .new {
    padding-top: 100px;
    width: 75%;
  }

  .list {
    width: 600px;
    padding: 40px;
  }

  .list ul li p {
    letter-spacing: 1px;
    line-height: 40px;
  }

  .list ul {
    padding-top: 45px;
  }

  .title h2 {
    writing-mode: vertical-rl;
    height: 250px;
    right: -50px;
    bottom: -30px;
  }

  .last {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    width: 80%;
  }

  .last img {
    height: 70px;
    margin-left: auto;
    margin-right: auto;
  }

  .last p {
    text-align: center;
  }

  .name ul {
    letter-spacing: 7px;
    margin-top: 200px;
  }
}

@media screen and (max-width: 750px) {
  .new {
    padding-top: 50px;
    width: 100%;
  }

  .list {
    width: 80%;
    padding: 20px;
  }

  .list ul li p {
    line-height: 30px;
  }

  .list ul {
    padding-top: 10px;
  }

  .title h2 {
    top: -35px;
    padding-top: 5px;
    padding-bottom: 5px;
    left: -10px;
    writing-mode: unset;
  }

  .last {
    margin-top: 70px;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
  }

  .last img {
    height: 60px;
  }

  .one,
  .two,
  .three {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .two {
    padding-left: 5%;
  }

  .last p {
    text-align: right;
  }

  .name ul {
    letter-spacing: 3px;
    margin-top: 150px;
    margin-bottom: 100px;
  }
}
</style>
